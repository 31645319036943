@import url('https://fonts.googleapis.com/css?family=Oswald&display=swap&subset=cyrillic');
h1, h2, h3, h4, nav, summary, button#start {
	font-family: 'Oswald', sans-serif;
	line-height: 35px;
	font-weight: bold;
}
h1 {
	line-height: 50px;
}
button#start {
	line-height: 32px;
}
button {
	padding: 15px 10px;
}

#settings[open] {
	height: 389.5px;
}

#settings p {
	max-width: 485px;
}

@media (min-width: 700px) {
	#about[open] {
		height: 494px;
	}
}

@media (max-width: 785px) and (min-width: 666px), (max-width: 460px) {
	nav ul>li {
		margin-right: 0;
	}

	nav ul li a, nav summary {
		padding: 5px 2px !important;
	}

	@supports (-moz-appearance: none) {
		nav summary {
			list-style-position: outside;
			padding-left: 0 !important;
			margin-left: 45px;
			text-indent: -14px;
		}
	}
	
	nav summary::-webkit-details-marker {
		margin-right: 1px;
	}
}

@media (max-width: 709px) and (min-width: 666px), (max-width: 460px) {
	nav details ul>li {
		padding: 5px 0 0 0;
	}

	nav details ul>li:first-child {
		margin-top: -10px;
	}

	nav ul li a, nav summary {
		padding: 5px 0 !important;
	}

	@supports (-moz-appearance: none) {
		nav summary {
			text-indent: -15px;
		}
	}
	
	nav summary::-webkit-details-marker {
		margin-right: 0;
	}
}

@media (max-width: 666px) {
	nav>ul {
		flex-wrap: wrap;
	}

	nav {
		height: 88px;
	}
}

@media (max-width: 378px) {
	nav {
		height: 132px;
	}
}